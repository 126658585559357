<template>
  <div style="min-height: 94vh" class="list bg-white w-full">
    <!--<title>Zenfiny | List Quotes</title>-->
    <div class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10">
      <h1 class="text-white text-xl lg:text-2xl font-bold"><i class="fas fa-calculator mr-2"></i>LIST QUOTES</h1>
      <div class="grid grid-cols-2 gap-4 mt-3 lg:mt-0">
        <a href="/registerquote" class="text-center text-sm lg:text-md btn-secondary inline-block lg:px-4 py-2 font-medium leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full">
          <i class="fas fa-plus-circle mx-1"></i> ADD QUOTE
        </a>
        <button  @click="toggleCollapse"
        class="text-center text-sm lg:text-md font-medium btn-search inline-block lg:px-4 py-2 font-regular leading-tight uppercase shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
        aria-expanded="false"
        aria-controls="collapseExample">
          <i class="fas fa-search color-text mx-1"></i> SEARCH FILTER
        </button>
      </div>
    </div>
    
    <div class="w-11/12 mx-auto"> 
      <transition name="slide-fade">
    <!-- Collapse content -->   
        <div v-if="isCollapsed" class="mt-2" id="collapseExample">
          <div class="block p-2 lg:p-6 rounded-lg shadow-lg bg-color">
            <div class="mb-6 flex">
              <div class="form-group w-1/2 mr-2">
                <label for="nameQuote" class="form-label inline-block mb-2 color-grey font-regular px-3 text-md ">Quote name</label>
                <input
                  v-model="search_name_provisional"
                  type="text"
                  class="form-control block w-full px-3 text-md font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  id="nameQuote"
                  placeholder=""
                />
              </div>
              <div class="form-group w-1/2 ml-2">
                <label for="codeQuote" class="form-label inline-block mb-2 color-grey font-regular px-3 text-md ">Quote code</label>
                <input
                  v-model="search_code_provisional"
                  type="text"
                  class="form-control block w-full px-3 text-md font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  id="codeQuote"
                  placeholder=""
                />
              </div>
            </div>
            <div class="mb-6 flex">
              <div v-if="isAdmin" class="form-group w-1/2 mr-2">
                <label for="userQuote" class="form-label inline-block mb-2 color-grey font-regular px-3 text-md ">User name</label>
                <input
                  v-model="search_user_provisional"
                  type="text"
                  class="form-control block w-full px-3 text-md font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 ocus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  id="userQuote"
                  placeholder=""
                />
              </div>
              <div class="form-group w-1/2 ml-2">
                <label
                  for="dateQuote"
                  class="form-label inline-block mb-2 color-grey font-regular px-3 text-md "
                  >Date</label
                >
                <input
                  v-model="search_date_provisional"
                  type="date"
                  class="form-control block w-full px-3 text-md font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  id="dateQuote"
                  placeholder=""
                />
              </div>
            </div>
            <div class="mb-6 flex">
              <div class="form-group w-1/2 mr-2">
                <label
                  for="brandQuote"
                  class="form-label inline-block mb-2 color-grey font-regular px-3 text-md "
                  >Brand:</label
                >
                <select
                  v-model="search_brand_provisional"
                  type="select"
                  class="form-control block w-full px-3 text-md font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  id="brandQuote"
                >
                  <option
                    v-for="brand in all_brands"
                    v-bind:key="brand.id"
                    :value="brand.id"
                  >
                    {{ brand.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="flex justify-between lg:justify-end items-center">
              <div v-if="isAdmin" class="form-check flex ml-2 pr-5">
                <input
                  v-model="search_deleted"
                  class="form-check-input h-4 w-4 border border-gray-300 focus:accent-indigo-100 rounded-sm bg-white accent-indigo-100 transition duration-200 mt-1 align-top float-left mr-2 cursor-pointer"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  style="background-color"
                />
                <label
                  class="form-check-label inline-block text-gray-800"
                  for="flexCheckDefault"
                >
                  Deleted Quotes
                </label>
              </div>
              <button
                v-on:click.stop.prevent="
                  setSearch();
                  getQuotes();
                "
                class="btn-primary inline-block px-2 py-2 font-regular text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-purple-600 focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full lg:w-2/12"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <i class="fas fa-search color-black pr-2"></i> SEARCH
              </button>
            </div>
          </div>
        </div>
      </transition>
      <div class="flex flex-col mt-5">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-5">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-primary border-b-2">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      Quote
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      Code
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      Brand
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      Creation date
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      Last update date
                    </th>
                    <th
                      v-if="isAdmin"
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      User
                    </th>
                    <th
                      v-if="!show_deleted"
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left w-28"
                    >
                      Actions
                    </th>
                    <th
                      v-else-if="isAdmin"
                      scope="col"
                      class="text-sm font-regular text-white px-6 py-2 text-left"
                    >
                      Restore
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="quote in quotes"
                    v-bind:key="quote.id"
                    class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                  >
                    <td class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap">
                      <button type="button" class="hover:text-gray-400" data-bs-toggle="modal" data-bs-target="#modalDetail"
                        @click="modal_detail = true; 
                                modal_monthly = false;
                                modal_offer = false;
                                setDetail(quote);">
                        {{ quote.name }}
                      </button>
                    </td>
                    <td
                      class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap"
                    >
                      {{ quote.code }}
                    </td>
                    <td
                      class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap"
                    >
                      {{ quote.brand.name }}
                    </td>
                    <td
                      class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap"
                    >
                      {{ date(quote.created_at) }}
                    </td>
                    <td
                      class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap"
                    >
                      {{ date(quote.updated_at) }}
                    </td>
                    <td
                      v-if="isAdmin"
                      class="text-sm text-gray-900 font-medium px-6 py-2 whitespace-nowrap"
                    >
                      {{ quote.user.name }}
                    </td>
                    <!-- download icon modify modal -->
                    <td
                      v-if="!show_deleted"
                      class="text-md text-center font-medium px-6 py-2 flex justify-between"
                    >
                      <button
                        type="button"
                        v-on:click.stop.prevent="
                         exportQuoteList(quote)
                        "
                        data-bs-dismiss="modal"
                        title="Download"
                        class="inline-block py-2 mr-4 font-medium text-md leading-tight color-restore transition duration-150 ease-in-out  hover:opacity-75"
                      >
                        <i class="fa fa-download text-black" ></i>
                      </button>
                      <button
                        @click="setDelete(quote)"
                        type="button"
                        class="inline-block py-2 font-medium text-md leading-tight color-delete transition duration-150 ease-in-out hover:opacity-70 hover:opacity-75"
                        data-bs-toggle="modal"
                        data-bs-target="#modalDelete"
                        title="Remove"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                    <td
                      v-if="show_deleted && isAdmin"
                      class="text-md text-center font-medium px-6 py-2 whitespace-nowrap"
                    >
                      <button
                        @click="restoreQuote(quote.id)"
                        type="button"
                        title="Restore"
                        class="inline-block py-2 font-medium text-md leading-tight color-restore transition duration-150 ease-in-out hover:opacity-70"
                      >
                      <i class="fas fa-undo"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal details quote -->
      <div
        class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="modalDetail"
        tabindex="-1"
        aria-labelledby="modalDetail"
        aria-modal="true"
        role="dialog"
      >
        <div
          v-if="modal_detail"
          class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
        >
          <div
            v-if="quoteDetail != null"
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Detailed quote
              </h5>
              <button
                type="button"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative rounded-lg px-2 py-5">
              <div class="w-11/12 mx-auto flex justify-between items-center">
                <div class="flex items-center">
                  <h3 class="pr-3 font-semibold text-lg">Name:</h3>
                  <h3>{{ quoteDetail.name }}</h3>
                </div>
                <div class="flex w-3/6">
                  <div class="w-full bg-color rounded-full py-2 lg:py-2">
                    <h2 class="text-xl font-bold text-center color-grey">
                      {{ quoteDetail.code }}
                    </h2>
                  </div>
                </div>
              </div>
              <div class="mt-3 w-11/12 mx-auto">
                <!-- <div class="flex mt-1">
                  <p class="font-semibold pr-2">Type:</p>
                  <p v-if="quoteDetail.type == 'hire_purchase'">
                    Hire Purchase
                  </p>
                  <p v-else-if="quoteDetail.type == 'lease'">Lease</p>
                  <p v-else class="text-red-500 font-medium">
                    Error recognizing type
                  </p>
                </div> -->
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Net cost:</p>
                  <p>£ {{ formatNumber(quoteDetail.cost) }}</p>
                </div>
                <div v-if="quoteDetail.not_vat_items" class="flex mt-1">
                  <p class="font-semibold pr-2">Not vat items:</p>
                  <p> £ {{ formatNumber(quoteDetail.not_vat_items) }}</p>
                </div>
                <div v-if="quoteDetail.not_vat_items" class="flex mt-1">
                  <p class="font-semibold pr-2">Total Net:</p>
                  <p> £ {{ totalNetCost }}</p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">VAT:</p>
                  <p>
                    £
                    {{ quoteDetail.vat ? formatNumber(quoteDetail.vat) : 0 }}
                  </p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Deposit:</p>
                  <p>
                    £
                    <span v-if="quoteDetail.type === 'hire_purchase'">
                      {{
                        (!quoteDetail.vat_deferal) ?  `${formatNumber(parseFloat(quoteDetail.deposit) + parseFloat(quoteDetail.vat))} inc VAT` : `${formatNumber(quoteDetail.deposit)}`
                      }}
                    </span>
                    <span v-if="quoteDetail.type === 'lease'">
                        {{
                          (quoteDetail.type === "lease" && quoteDetail.deposit > 0) ? `${formatNumber(quoteDetail.deposit)} + ${formatNumber(vatPayment(quoteDetail.deposit))} VAT` : ""
                        }}
                    </span>
                  </p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">VAT deferral:</p>
                  <p v-if="quoteDetail.vat_deferal">Yes</p>
                  <p v-if="!quoteDetail.vat_deferal">No</p>
                </div>
                <div v-if="quoteDetail.balloon || (quoteDetail.vat_deferal && quoteDetail.vat)" class="flex mt-1">
                  <p v-if="!quoteDetail.vat_deferal" class="font-semibold pr-2">Balloon fall:</p>
                  <p v-else class="font-semibold pr-2">VAT deferral fall:</p>
                  <p>month
                    {{
                      quoteDetail.balloon_fall ? quoteDetail.balloon_fall : 0
                    }}
                  </p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Monthly payments:</p>
                  <p v-if="quoteDetail.type === 'lease'">
                    £
                    {{
                      formatNumber(quoteDetail.payment) +
                      " + " +
                      formatNumber(vatPayment(quoteDetail.payment)) +
                      " VAT"
                    }}
                  </p>
                  <p v-else>£ {{ formatNumber(quoteDetail.payment) }}</p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Term:</p>
                  <p>{{ quoteDetail.term }} months</p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Total interest:</p>
                  <p>£ {{ formatNumber(quoteDetail.total_interest)  }}</p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Interest to lender:</p>
                  <p>{{ formatNumber(quoteDetail.interest) }}%</p>
                </div>
                <div v-if="quoteDetail.balloon > 0" class="flex mt-1">
                  <p class="font-semibold pr-2">Balloon:</p>
                  <p>
                    £
                    {{
                      quoteDetail.balloon
                        ? formatNumber(quoteDetail.balloon)
                        : 0
                    }}
                  </p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Commission:</p>
                  <p>{{
                      this.quoteDetail.commission ? ` ${((parseFloat(this.quoteDetail.commission) /(parseFloat(this.quoteDetail.cost) + parseFloat(this.quoteDetail.not_vat_items || 0) - parseFloat(this.quoteDetail.deposit))) * 100).toFixed(2)} %`: ""
                    }}
                    <span>(£ {{ this.quoteDetail.commission ? formatNumber(this.quoteDetail.commission) :0 }})</span>
                  </p>
                </div>
                <!-- <div class="flex mt-1">
                  <p class="font-semibold pr-2">Advanced months:</p>
                  <p>
                    {{
                      quoteDetail.advanced_pay ? quoteDetail.advanced_pay : 0
                    }}
                    months
                  </p>
                </div> -->
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Gross yield:</p>
                  <p>{{ formatNumber(quoteDetail.gross_yield) }}%</p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">APR:</p>
                  <p>{{ formatNumber(quoteDetail.apr) }}%</p>
                </div>
                <div class="flex mt-1">
                  <p class="font-semibold pr-2">Flat rate:</p>
                  <p>{{ formatNumber(quoteDetail.flat_rate) }}%</p>
                </div>
              </div>
            </div>
            <div class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-between py-4 px-2 rounded-b-md">
              <!--Edit quote button modal-->
              <div class="w-28 lg:w-40">
                <button @click="editQuote" type="button" class="btn-edit w-full font-regular text-sm inline-block py-2 rounded-full 
                focus:outline-none focus:ring-0 hover:bg-color transition duration-150 ease-in-out" data-bs-dismiss="modal">
                  <i class="fas fa-edit"></i> Edit quote
                </button>
              </div>
              <div class="w-28 lg:w-40">
                <button type="button" class="btn-primary w-full font-regular text-sm inline-block py-2 rounded-full focus:outline-none 
                  focus:ring-0 transition duration-150 ease-in-out"
                  @click="modal_offer = true;
                          modal_detail = false;
                          modal_monthly = false;
                          getOffer(quoteDetail.id);">
                  <i class="fas fa-download pr-1 lg:pr-3"></i> Offer
                </button>
              </div>
              <div class="w-28 lg:w-40">
                <button
                  type="button"
                  @click="
                    modal_monthly = true;
                    modal_detail = false;
                    modal_offer = false;
                  "
                  class="btn-secondary w-full font-regular text-sm inline-block py-2 rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
                >
                  <i class="fas fa-download pr-1 lg:pr-3"></i> Quote
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="modal_offer"
          class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
        >
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Offer
              </h5>
              <button
                type="button"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative py-2 px-2">
              <div class="modal-dialog">
                <label
                  class="pr-8 lg:pr-auto form-label inline-block color-grey font-regular px-3 text-lg lg:text-lg"
                  for="assets"
                  >Assets</label
                >
                <textarea
                  id="assets"
                  v-model="offer_assets"
                  value=""
                  class="form-control block w-full px-3 text-sm font-normal text-gray-800 bg-transparent border-input rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                  v-bind:class="{
                    'form-control': true,
                    'is-invalid': offer_assets == '' || offer_assets == null,
                  }"
                />
                <p
                  class="text-sm lg:text-md font-medium absolute top-4 lg:top-5 right-4 text-gray-500 font-medium"
                ></p>
                <div class="invalid-feedback">This field cannot be empty</div>
                <label
                  for="conditions"
                  class="mt-3 pr-8 lg:pr-auto form-label inline-block color-grey font-regular px-3 text-lg lg:text-lg"
                  >Conditions</label
                >
                <textarea
                  id="conditions"
                  v-model="offer_conditions"
                  class="form-control block w-full px-3 text-sm font-normal text-gray-800 bg-transparent border-input rounded-xl transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                />
              </div>
            </div>
            <div
              class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
            >
              <button
                @click="generateExportPdfQuote"
                class="btn-primary inline-block px-2 py-2 font-regular text-black text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
              >
                Save and download
              </button>
            </div>
          </div>
        </div>
        <div
          v-else
          class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
        >
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Monthly history in pdf
              </h5>
              <button
                type="button"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative py-6 px-2">
              <p>Do you want to show the payment history in the pdf?</p>
            </div>
            <div
              class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
            >
              <button
                type="button"
                v-on:click.stop.prevent="
                  monthly_pdf = false;
                  export_pdf = true;
                  export_pdf_quote = false;
                  modal_detail = true;
                  generateReport();
                "
                
                class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                Don't show
              </button>
              <button
                type="button"
                v-on:click.stop.prevent="
                  monthly_pdf = true;
                  export_pdf = true;
                  export_pdf_quote = false;
                  modal_detail = true;
                  generateReport();
                "
      
                class="inline-block px-6 py-2 btn-accept font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                Show
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal Delete -->
      <div
        class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="modalDelete"
        tabindex="-1"
        aria-labelledby="modalDelete"
        aria-modal="true"
        role="dialog"
      >
        <div
          class="modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
        >
          <div
            v-if="quoteDelete != null"
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
            >
              <h5
                class="text-2xl font-regular leading-normal text-white"
                id="exampleModalScrollableLabel"
              >
                Delete quote
              </h5>
              <button
                type="button"
                class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray hover:opacity-75 hover:no-underline"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="modal-body relative py-6 px-2">
              <p>Are you sure you want to remove this quote from the list?</p>
            </div>
            <div
              class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
            >
              <button
                type="button"
                v-on:click.stop.prevent="deleteQuote(quoteDelete.id)"
                data-bs-dismiss="modal"
                class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
              >
                <i class="far fa-trash-alt"></i>
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      <div aria-label="Page navigation" class="bg-transparent pb-4">
        <div class="flex flex-col mt-5">
          <ul class="inline-flex flex justify-end">
            <li>
              <button
                @click="getQuotes(current_page - 1)"
                :disabled="current_page == 1"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black rounded-l-lg focus:shadow-outline hover:bg-indigo-100"
              >
                <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </li>
            <li v-if="current_page > 1">
              <button
                @click="getQuotes(1)"
                :disabled="current_page == 1"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                1
              </button>
            </li>
            <li v-if="current_page > 2">
              <button
                disabled
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                ...
              </button>
            </li>

            <li>
              <button
                disabled
                class="h-10 px-5 text-white transition-colors duration-150 bg-primary border border-r-0 border-black focus:shadow-outline"
              >
                {{ current_page ? current_page : 1 }}
              </button>
            </li>

            <li v-if="current_page < last_page - 1">
              <button
                disabled
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                ...
              </button>
            </li>
            <li v-if="current_page < last_page">
              <button
                @click="getQuotes(last_page)"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
              >
                {{ last_page }}
              </button>
            </li>

            <li>
              <button
                @click="getQuotes(current_page + 1)"
                :disabled="current_page == last_page"
                class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-black rounded-r-lg focus:shadow-outline hover:bg-indigo-100"
              >
                <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                    fill-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </div>

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="pdfFileName"
        :pdf-quality="1.0"
        :quality="0.2"
        :manual-pagination="false"
        :compress="true"
        pdf-format="a4"
        pdf-content-width="800px"
        margin:0
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
      
        <section slot="pdf-content" class="m-0 p-0">
          <pdf-quote
            v-if="export_pdf"
            :quote="quoteDetail"
            :monthly_history="quote_monthly"
            :with_monthly="monthly_pdf"
            :image_brand="quoteDetail!=null&&quoteDetail.brand&&quoteDetail.brand.img ? quoteDetail.brand.img : image_brand_test"
            
          />
          <div v-if="export_pdf_quote == false"
          style="page-break-before: always;"></div>
          
          
          <monthly-summary style="margin:20px;"
          v-if="monthly_pdf"
            :quote="quoteDetail"
            :monthly_history="quote_monthly"
            :with_monthly="monthly_pdf"
            :image_brand="quoteDetail!=null&&quoteDetail.brand&&quoteDetail.brand.img ? quoteDetail.brand.img : image_brand_test"
          ></monthly-summary>

          <OfferPdf
            v-if="export_pdf_quote == true"
            :quote="quoteDetail"
            :offer="{ assets: offer_assets, conditions: offer_conditions }"
          />
          
        </section>
      </vue-html2pdf>
    </div>
  </div>
</template>

<script>
import QuoteService from "../../services/af-quote/QuoteService";
import BrandService from "../../services/af-quote/BrandService";
import { mapGetters } from "vuex";
import PdfQuote from "./PdfQuote.vue";
import OfferPdf from "./OfferPdf.vue";
import MonthlySummary from "./RegisterQuote/monthly-summary/monthly-summary.vue";

import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";

export default {
  name: "list-quotes",
  components: { VueHtml2pdf, PdfQuote, OfferPdf,MonthlySummary },
  data() {
    return {
      isCollapsed: false,
      quotes: null,

      all_brands: null,
      //filters
      search_deleted: false,
      search_code_provisional: null,
      search_date_provisional: null,
      search_name_provisional: null,
      search_user_provisional: null,
      search_brand_provisional: null,

      search_code: null,
      search_date: null,
      search_name: null,
      search_user: null,
      search_brand: null,

      //pagination
      current_page: null,
      last_page: null,
      show_deleted: false,

      //modals
      quoteDetail: null,
      image_brand_test: "/img/ejemplo.brand.png",
      quote_monthly: null,
      quoteDelete: null,

      modify_offer: false,
      offer_assets: null,
      offer_conditions: null,

      modal_offer: false,
      modal_detail: false,
      modal_monthly: false,
      modal_pdf: false,
      export_pdf: false,
      export_pdf_quote: false,
      monthly_pdf: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
      isAdmin: "auth/isAdmin",
    }),
    pdfFileName: function () {
      var date = this.date(Date.now());
      if (this.export_pdf) {
        return (
          "Quote" +
          "-" +
          (this.quoteDetail != null ? this.quoteDetail.name : "pdf") +
          "-" +
          date +
          ".pdf"
        );
      } else {
        return (
          "Offer" +
          "-" +
          (this.quoteDetail != null ? this.quoteDetail.name : "pdf") +
          "-" +
          date +
          ".pdf"
        );
      }
    },

    totalNetCost:function(){
      var not_vat_items = parseFloat(this.quoteDetail.not_vat_items);
      var cost = parseFloat(this.quoteDetail.cost);
      //console.log(not_vat_items+cost);
      return this.formatNumber(not_vat_items+cost);
    }
  },

  async created() {
    this.getQuotes();
    this.getBrands();
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;  // Alterna el estado del colapso
    },
    generateExportPdfQuote() {
      if (this.offer_assets != null && this.offer_assets != "") {
        this.submitOffer();
        this.export_pdf = false;
        this.export_pdf_quote = true;
        this.generateReport();
      }
    },
    generateReport() {
      this.modal_detail = true;
      this.$refs.html2Pdf.generatePdf();
    },
    async getQuotes(num = null) {
      try {
        this.show_deleted = this.search_deleted;
        let query = this.buildSearchQuery();
        if (this.show_deleted)
          query += query == "" ? "deleted&" : "&" + "deleted&";
        if (num) query += query == "" ? "page=" + num : "&page=" + num;
        const quote_service = new QuoteService();
        const quotes_data = await quote_service.getQuotes(query);
        this.quotes = quotes_data.data;
        //console.log(this.quotes, 'todas las cuotas que se cargan en la lista')
        this.current_page = quotes_data.current_page;
        this.last_page = quotes_data.last_page;
      } catch (error) {
        console.error(error);
      }
    },

    async getBrands() {
      const brand_service = new BrandService();
      this.all_brands = await brand_service.getAllBrands();
    },

    setSearch() {
      this.search_code = this.search_code_provisional;
      if (this.search_code)
        this.search_code = this.search_code.replace("#", "");
      this.search_date = this.search_date_provisional;
      this.search_name = this.search_name_provisional;
      this.search_user = this.search_user_provisional;
      this.search_brand = this.search_brand_provisional;
    },

    buildSearchQuery() {
      let query = "";
      if (this.search_code) query += "code=" + this.search_code + "&";
      if (this.search_date) query += "date=" + this.search_date + "&";
      if (this.search_name) query += "name=" + this.search_name + "&";
      if (this.search_user) query += "user=" + this.search_user + "&";
      if (this.search_brand) query += "brand=" + this.search_brand + "&";
      return query;
    },
    async exportQuoteList(quote){
      await this.setDetail(quote);
      this.monthly_pdf = false;
      this.export_pdf = true;
      this.export_pdf_quote = false;
      this.generateReport();
    },
    async setDetail(quote) {
      this.quoteDetail = null;
      this.quoteDetail = quote;
      //console.log(this.quoteDetail, 'info que se pasa a thisquotedetail')
      if(this.quoteDetail.not_vat_items){
        //console.log("Tiene not_vat_items ");
        //console.log('not_vat_items',this.quoteDetail.not_vat_items);
      }else{
        //console.log("NO Tiene not_vat_items ");
      }

      // Get brand image
      const brand_service = new BrandService();
      this.quoteDetail.brand.img = await brand_service.getImg(
        this.quoteDetail.brand.id
      );
      // console.log('img brand');
      // console.log(this.quoteDetail.brand.img);
      this.image_brand_test=this.quoteDetail.brand.img;
      // Calculate Quote
      const quote_service = new QuoteService();
      try {
        const form = {
          cost: quote.cost,
          vat: quote.vat,
          deposit: quote.deposit,
          interest: quote.interest,
          commission: quote.commission,
          advanced_pay: quote.advanced_pay,
          term: quote.term,
          balloon: quote.balloon,
          per: quote.balloon_fall,
          vat_deferal: quote.vat_deferal,
        };
        let monthly_data = await quote_service.calculateQuote(form);
        this.quote_monthly = monthly_data.dynamic_cost;

        if (this.quoteDetail.type == "lease") {
          const vat = this.vatPayment(this.quoteDetail.payment);
          this.quote_monthly = this.quote_monthly.map((element) => {
            element.payments = parseFloat(element.payments.replace(",", ""));
            return element;
          });
          let sumVat = function (element, num) {
            if (element.month > 0) element.payments += num;
            return element;
          };
          this.quote_monthly = this.quote_monthly.map((element) => {
            return sumVat(element, vat);
          });
          this.quote_monthly = this.quote_monthly.map((element) => {
            element.payments = this.formatNumber(element.payments);
            return element;
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    setDelete(quote) {
      this.quoteDelete = quote;
    },
    date(value) {
      return moment(value).format("DD-MM-yyyy");
    },

    async go(num) {
      try {
        let query = "";
        if (this.show_deleted) query += "&deleted&";
        let quote_service = new QuoteService();
        let quotes_data = await quote_service.getQuotes("page=" + num + query);
        this.quotes = quotes_data.data;
        this.current_page = quotes_data.current_page;
        this.last_page = quotes_data.last_page;
      } catch (error) {
        console.error(error);
      }
    },

    async deleteQuote(id) {
      try {
        const quote_service = new QuoteService();
        await quote_service.delete(id);
        this.quoteDelete = null;
        this.go(
          this.quotes.length > 1 ? this.current_page : --this.current_page
        );
      } catch (error) {
        console.error(error);
      }
    },

    async restoreQuote(id) {
      try {
        const quote_service = new QuoteService();
        await quote_service.restore(id);
        this.go(
          this.quotes.length > 1 ? this.current_page : --this.current_page
        );
      } catch (error) {
        console.error(error);
      }
    },

    formatNumber(x) {
      // console.log("type x");
      // console.log(x);
      if (isNaN(x) || x == null) {
        x = 0.0;
      } else {
        x = parseFloat(x);
      }

      let nf = new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      nf.resolvedOptions().minimumFractionDigits = 2;
      // console.log(nf.format(x));
      return nf.format(x);
    },

    editQuote() {
      const param = this.quoteDetail;
      //console.log(this.quoteDetail, 'parametros que se pasan para editar la cuota a traves de la ruta');
      this.$router.replace({ name: "registerQuote", params: { quote: param } });
    },

    async getOffer(id) {
      try {
        this.monthly_pdf = false;
        this.modify_offer = false;
        this.offer_assets = null;
        this.offer_conditions = null;
        const quote_service = new QuoteService();
        const data = await quote_service.getOffer(id);
        //console.log("data list sercide getOffer");
        // console.log(data);
        if (data.quote_id != null || data.quote_id) {
          // console.log("Yess !!!se ha encontrado un offer asociado a la quota");
          this.offer_assets = data.assets;
          this.offer_conditions = data.conditions;
          this.modify_offer = true;
        } else {
          if (!data.success) {
            this.modify_offer = false;
          }
        }
      } catch (error) {
        this.modify_offer = false;
      } finally {
        // console.log(this.modify_offer);
      }
    },

    async submitOffer() {
      if (this.offer_assets == "" || this.offer_assets == null) return;
      this.export_pdf_quote = false;
      const quote_service = new QuoteService();
      try {
        if (this.modify_offer)
          await quote_service.updateOffer(
            { assets: this.offer_assets, conditions: this.offer_conditions },
            this.quoteDetail.id
          );
        else
          await quote_service.createOffer({
            quote_id: this.quoteDetail.id,
            assets: this.offer_assets,
            conditions: this.offer_conditions,
          });
      } catch (error) {
        console.error(error);
      } finally {
        this.offer_assets = null;
        this.offer_conditions = null;
        this.modal_detail = true;
        this.modal_offer = false;
      }
    },

    vatPayment(payment) {
      return parseFloat(
        parseFloat(payment) * parseFloat(process.env.VUE_APP_UK_VAT)
      );
    },


  },
};
</script>
