<template>
  <div style="min-height: 94vh" class="bg-white w-full">
    <title>Zenfiny | Create Brand</title>
    <div
      class="
        w-full
        bg-secondary
        py-2
        lg:flex
        justify-between
        items-center
        px-3
        lg:px-24
      "
    >
      <h1 class="text-white text-xl lg:text-2xl font-bold">
        <i class="fas fa-tags"></i>
        {{ this.edit_mode ? 'EDIT BRAND' : 'NEW BRAND' }}
      </h1>
    </div>
    <div class="w-10/12 mx-auto">
      <form class="">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 my-8">
          <div class="form-group">
            <label
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >Name:</label
            >
            <input
              v-model="brandname"
              type="text"
              class="
                form-control
                block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-800
                bg-transparent
                border-input
                rounded-full
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:border-gray-400 focus:outline-none
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': brandname == '' && submitted,
              }"
            />
            <div class="invalid-feedback">Name cannot be empty</div>
          </div>
          <div class="form-group">
            <label
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >Color:</label
            >
            <input
              v-model="brandcolor"
              type="color"
              class="
              form-control
              block
              w-full
              py-2 px-3
              h-11
              text-base
              font-normal
              text-gray-800
              bg-transparent
              border-input
              rounded-full
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:border-gray-400 focus:outline-none
             form-control
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': brandcolor == '' && submitted,
              }"
            />
            <div class="invalid-feedback">Color cannot be empty</div>
          </div>
          <div class="form-group">
            <label
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >URL:</label
            >
            <input
              v-model="url"
              type="url"
              class="
                form-control
                block block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-800
                bg-transparent
                border-input
                rounded-full
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:border-gray-400 focus:outline-none
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': url == '' && submitted,
              }"
            />
            <div class="invalid-feedback">Not valid URL</div>
          </div>
            <div class="form-group">
            <label
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >Telephone:</label
            >
            <input
              v-model="brandtelephone"
              type="tel"
              class="
                form-control
                block block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-800
                bg-transparent
                border-input
                rounded-full
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:border-gray-400 focus:outline-none
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': brandtelephone == '' && submitted,
              }"
            />
          </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
          <div class="form-group">
            <label
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >Email:</label
            >
            <input
              v-model="brandemail"
              type="email"
              class="
                form-control
                block block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-800
                bg-transparent
                border-input
                rounded-full
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:border-gray-400 focus:outline-none
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': brandemail == '' && submitted,
              }"
            />
          </div>
          <div class="form-group">
          <label
            class="
              form-label
              inline-block
              mb-2
              color-grey
              font-regular
              px-3
              text-lg
              lg:text-xl
            "
            >Image:</label
          >
          <div
          v-if="this.image==null">
            <input
            style="background: #f3f4f6"
            accept="image/*"
            value="image"
            @change="onFilePicked"
            type="file"
            class="
              form-control
              block block
              w-full
              px-3
              py-2
              text-base
              font-normal
              text-gray-800
              bg-transparent
              border-input
              rounded-full
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:border-gray-400 focus:outline-none
            "
          />
          </div>
          <div
          v-else>
          <img 
            :src="image" 
            style="max-width: 100%;" />
          <input
            style="background: #f3f4f6"
            accept="image/*"
            value="image"
            @change="onFilePicked"
            type="file"
            class="
              form-control
              block block
              w-full
              px-3
              py-2
              text-base
              font-normal
              text-gray-800
              bg-transparent
              border-input
              rounded-full
              transition
              ease-in-out
              m-0
              focus:text-gray-700 focus:border-gray-400 focus:outline-none
            "
          />
          </div>
          
        </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
          <div class="form-group">
            <label
              for="address-line-1"
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >Address Line 1:</label
            >
            <textarea
              v-model="brand_adress_line_1"
              id="address-line-1"
              type="text"
              class="
                form-control
                block block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-800
                bg-transparent
                border-input
                rounded-2xl
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:border-gray-400 focus:outline-none
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': brand_adress_line_1 == '' && submitted,
              }"
            />
          </div>
          <div class="form-group">
            <label
              for="address-line-2"
              class="
                form-label
                inline-block
                mb-2
                color-grey
                font-regular
                px-3
                text-lg
                lg:text-xl
              "
              >Address Line 2:</label
            >
            <textarea
              v-model="brand_adress_line_2"
              id="address-line-2"
              type="text"
              class="
                form-control
                block block
                w-full
                px-3
                py-2
                text-base
                font-normal
                text-gray-800
                bg-transparent
                border-input
                rounded-2xl
                transition
                ease-in-out
                m-0
                focus:text-gray-700 focus:border-gray-400 focus:outline-none
              "
              v-bind:class="{
                'form-control': true,
                'is-invalid': brand_adress_line_2 == '' && submitted,
              }"
            />
          </div>
          
        </div>
        <div v-if="edit_mode" class="flex justify-end px-4 py-3">
        </div>

        <div class="flex justify-center mt-12 pb-5">
          <button
            v-on:click.stop.prevent="submit"
            class="
              w-4/6
              lg:w-2/6
              btn-primary
              font-regular
              py-2
              text-lg
              lg:text-xl
              rounded-full
            "
          >
            <i class="fas fa-plus-circle pr-3"></i>
            {{ !edit_mode ? "Add Brand" : "Save Brand" }}
          </button>
        </div>
      </form>
      <div
        v-if="error_register"
        class="
          bg-red-100
          border
          font-medium
          border-red-400
          text-red-700
          px-4
          py-2
          rounded
          relative
          mt-2
        "
        role="alert"
      >
        An error has ocurred and could not create the brand, try to select
        another image.
      </div>
    </div>
  </div>
</template>

<script>
import BrandService from "../../services/af-quote/BrandService";

export default {
  data() {
    return {
      brandname: "",
      brandcolor: "",
      url: "",
      brandtelephone: "",
      brandemail: "",
      brand_adress_line_1: "",
      brand_adress_line_2: "",
      image: null,
      img_name: null,
      submitted: false,

      id: null,
      edit_mode: false,
      delete_image: false,
      error_register: false,
    };
  },

  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      const brand_service = new BrandService();
      const data = await brand_service.getBrand(this.id);
      this.brandname = data.name;
      this.brandcolor = data.color;
      this.url = data.url;
      console.log(data.img);
      this.image = data.img ? `${process.env.VUE_APP_API_URL}/${data.img}` : null;
      this.brandtelephone = data.telephone;
      this.brandemail = data.email;
      this.brand_adress_line_1 = data.address_line_1;
      this.brand_adress_line_2 = data.address_line_2;
      this.edit_mode = true;
    }
  },

  methods: {
    async submit() {
      try {
        this.submitted = true;

        let formData = new FormData();
        formData.append("name", this.brandname);
        formData.append("color", this.brandcolor);
        formData.append("url", this.url);
        if (this.img_name != null) {
          formData.append("image", this.image);
          formData.append("img", this.img_name);
        }
        formData.append("telephone", this.brandtelephone);
        formData.append("email", this.brandemail);
        formData.append("address_line_1", this.brand_adress_line_1);
        formData.append("address_line_2", this.brand_adress_line_2);

        const brand_service = new BrandService();
        if (!this.edit_mode) await brand_service.create(formData);
        else {
          formData.append("delete_image", this.delete_image ? 1 : 0);
          await brand_service.update(this.id, formData);
        }

        this.$router.replace("/");
      } catch (error) {
        this.error_register = true;
        console.error(error);
      }
    },

    onFilePicked(event) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      this.img_name = this.image.name;
    },
  },
};
</script>
