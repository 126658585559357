<template>
  <div class="bg-white w-full h-full">
    <div
      class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
    >
      <h1 class="text-white text-xl lg:text-2xl font-bold">
        <i class="fas fa-file"></i>
        EDIT FORM
      </h1>
    </div>
    <div class="w-10/12 mx-auto">
      <!-- <div
        v-if="correct_register"
        class="bg-auto text-center rounded-xl font-normal mb-2 color-black bg-green-200 text-lg lg:text-xl absolute bottom-0 p-2 right-0 w-64 ">
        <p class="mx-2 justify-center">
          Form {{ this.domain }} edited, click
          <router-link class="underline font-medium" to="/forms">here</router-link>
          to see all the forms
        </p>
      </div> -->

      <!-- información general -->
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">Add this code of your website</h2>
        <div class="grid grid-cols-2 gap-4">
          <div class="">
            <p class="text-md mt-4 text-gray-600">
              Embed the calculator code on your website.
            </p>
            <div class="relative">
              <input
                :value="
                  this.vue_api_url +
                  '/calculator/finance-calculator/' +
                  this.form_id
                "
                id="calculator"
                class="w-full rounded-lg border border-gray-400 p-3 mt-2 italic font-bold flex justify-between hover:bg-gray-100"
              />
              <i
                class="far fa-copy hover:opacity-50 cursor-pointer absolute right-4 top-4"
                @click="copyText()"
              ></i>
            </div>
            <p class="text-sm text-gray-600 mt-1 mb-8">
              &lt;iframe title="form-calculadora"
              src="https://api.afcalc.com/calculator/finance-calculator/..."
              width="300" height="800"&lt;iframe&gt;
            </p>
            <p>
              Calculator preview
              <span class="underline italic font-semibold hover:opacity-70">
                <a
                  :href="
                    this.vue_api_url +
                    '/calculator/finance-calculator/' +
                    this.form_id
                  "
                  target="_blank"
                  >here</a
                ></span
              >
            </p>
          </div>
          <div class="">
            <p class="text-md mt-4 text-gray-600">
              Embed the apply form code on your website.
            </p>
            <div class="relative">
              <input
                :value="this.vue_api_url + '/form/finance-form/' + this.form_id"
                id="finance"
                class="w-full rounded-lg border border-gray-400 p-3 mt-2 italic font-bold flex justify-between hover:bg-gray-100"
              />
              <i
                class="far fa-copy hover:opacity-50 cursor-pointer absolute right-4 top-4"
                @click="copyTextFinance()"
              ></i>
            </div>
            <p class="text-sm text-gray-600 mt-1 mb-8">
              &lt;iframe title="form-calculadora"
              src="https://api.afcalc.com/form/finance-form/..." width="300"
              height="800"&lt;iframe&gt;
            </p>
            <p class="">
              Apply form preview
              <span class="underline italic font-semibold hover:opacity-70">
                <a
                  :href="
                    this.vue_api_url + '/form/finance-form/' + this.form_id
                  "
                  target="_blank"
                  >here</a
                ></span
              >
            </p>
          </div>
        </div>
      </div>

      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">1. General information</h2>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
          <!-- domain -->
          <div class="form-group">
            <p class="mb-1">Domain *</p>
            <input
              placeholder="https://www.example.com"
              v-model="domain"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': domain_invalid == true && form_sended == true,
              }"
            />
            <div v-if="domain_invalid == true && form_sended == true">
              <p style="color: red">{{ this.domain_error }}</p>
            </div>
          </div>
          <!-- code -->
          <div class="form-group">
            <p class="mb-1">Code *</p>
            <input
              v-model="code"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': code_invalid == true && form_sended == true,
              }"
            />
            <div v-if="code_invalid == true && form_sended == true">
              <p style="color: red">{{ code_error }}</p>
            </div>
          </div>
          <!-- email -->
          <div class="form-group">
            <p class="mb-1">Email *</p>
            <input
              v-model="email"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': email_invalid == true && form_sended == true,
              }"
            />
            <div v-if="email_invalid == true && form_sended == true">
              <p style="color: red">{{ email_error }}</p>
            </div>
          </div>
          <!-- telephone -->
          <div class="form-group">
            <p class="mb-1">Telephone *</p>
            <input
              v-model="telephone"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
              v-bind:class="{
                'form-control': true,
                'is-invalid': telephone_invalid == true && form_sended == true,
              }"
            />
            <div v-if="telephone_invalid == true && form_sended == true">
              <p style="color: red">{{ telephone_error }}</p>
            </div>
          </div>

          <!--Terms and conditions link-->
          <div class="form-group">
            <p class="mb-1">Terms and conditions link</p>
            <input
              placeholder="https://www.example.com"
              v-model="terms_link"
              class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
            />
          </div>
          <div class="form-group py-8">
            <div class="flex flex-row">
              <label class="switch">
                <input type="checkbox" id="myCheck" v-model="show_checkbox" />
                <span class="slider round"></span>
              </label>
              <p class="pl-4 text-md lg:text-lg">show option to receive information</p>
            </div>
            <div v-if="show_checkbox">
              <p class="mb-1">Receive information text</p>
              <input
                v-model="information_text"
                class="px-3 py-1 form-control w-full font-normal text-gray-700 bg-transparent border-input rounded-xl"
                v-bind:class="{
                  'form-control': true,
                  'is-invalid':
                    show_checkbox == true &&
                    form_sended == true &&
                    information_text == null,
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">2. Style form</h2>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-8">
          <!-- background input -->
          <div class="form-group">
            <label for="favcolor">Background input </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="background_input"
              name="background_input"
              :value="background_input"
            />
          </div>

          <!-- background color -->
          <div class="form-group">
            <label for="favcolor">Background color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="background_color"
              name="background_color"
              :value="background_color"
            />
          </div>
          <!-- text color -->
          <div class="form-group">
            <label for="favcolor">Text color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="text_color"
              name="text_color"
              value="#000000"
            />
          </div>
          <!-- choose a font -->
          <div class="form-group">
            <label for="fonts">Choose a font</label>

            <select
              v-model="selected_font"
              name="fonts"
              id="fonts"
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
            >
              <option value="1" style="font-family: Inter">Inter</option>
              <option value="2" style="font-family: Merriweather">
                Merriweather
              </option>
              <option value="3" style="font-family: Montserrat">
                Montserrat
              </option>
              <option value="4" style="font-family: Poppins">Poppins</option>
              <option value="5" style="font-family: Lexend">Lexend</option>
              <option value="6" style="font-family: Lato">Lato</option>
            </select>
          </div>
          <!-- Primary color -->
          <div class="form-group">
            <label for="favcolor">Primary color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="primary_color"
              name="primary_color"
              :value="primary_color"
            />
          </div>
          <!-- Secondary color -->
          <div class="form-group">
            <label for="favcolor">Secondary color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="secondary_color"
              name="secondary_color"
              :value="secondary_color"
            />
          </div>
          <!-- text button color -->
          <div class="form-group">
            <label for="favcolor">Text button color </label>
            <input
              class="form control px-2 py-1 w-full font-normal bg-transparent border-input rounded-xl h-11"
              type="color"
              id="text_btn_color"
              name="text_btn_color"
              :value="text_btn_color"
            />
          </div>
        </div>
        <!-- border radius -->
        <div class="mt-4">
          <p>Choose a border radius size for the buttons</p>
          <div
            class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex items-center"
          >
            <div class="form-group">
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="value"
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form control w-16 text-base font-normal text-gray-800 bg-transparent border-input rounded-full focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="value"
              />
              px
            </div>
          </div>
        </div>
      </div>
      <!--CLAIM TEXT-->
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">3. Apply step 0: introduction</h2>
        <div class="form-group mt-4">
          <p class="mb-2">
            Enter claim text for step 0 of the application form
          </p>
          <textarea
            v-model="claim"
            class="form-control w-full font-normal text-gray-600 bg-transparent border-input rounded-3xl focus:text-gray-700 focus:border-gray-400 h-32 p-3 italic"
          />
        </div>
      </div>

      <!--CONGRATULATIONS TEXT-->

      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">
          4. Apply and calculator congratulations text
        </h2>
        <div class="form-group mt-4">
          <p class="mb-2">
            Enter congratulations text for the application form and calculator
          </p>
          <textarea
            v-model="congratulations_text"
            class="form-control w-full font-normal text-gray-600 bg-transparent border-input rounded-3xl focus:text-gray-700 focus:border-gray-400 h-32 p-3 italic"
          />
        </div>
      </div>

      <!--RATIO PERCENTAGE-->
      <div class="my-8 shadow-xl lg:p-6 p-3 rounded-3xl">
        <h2 class="text-xl font-semibold">5. Calculator credit rate</h2>
        <p>Select what configuration you want to give to the credits.</p>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8 mt-4">
          <!-- excellent -->
          <div class="grid grid-cols-3 gap-4 mt-4 lg:mt-0">
            <div class="form-group col-span-2">
              <p>Percentage excellent</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="excellent_value"
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form control w-14 text-base font-normal text-gray-800 bg-transparent border-input rounded-full focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="excellent_value"
              />
              %
            </div>
          </div>
          <!-- good -->
          <div class="grid grid-cols-3 gap-4 mt-4 lg:mt-0">
            <div class="form-group col-span-2">
              <p>Percentage good</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="good_value"
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form control w-14 text-base font-normal text-gray-800 bg-transparent border-input rounded-full focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="good_value"
              />
              %
            </div>
          </div>
          <!-- fair -->
          <div class="grid grid-cols-3 gap-4 mt-4 lg:mt-0">
            <div class="form-group col-span-2">
              <p>Percentage fair</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="fair_value"
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form control w-14 text-base font-normal text-gray-800 bg-transparent border-input rounded-full focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="fair_value"
              />
              %
            </div>
          </div>
          <!-- poor -->
          <div class="grid grid-cols-3 gap-4 mt-4 lg:mt-0">
            <div class="form-group col-span-2">
              <p>Percentage poor</p>
              <input
                class="w-full"
                type="range"
                min="0"
                max="100"
                step="1"
                v-model="poor_value"
              />
            </div>
            <div class="form-group">
              <input
                type="number"
                class="form control w-14 text-base font-normal text-gray-800 bg-transparent border-input rounded-full focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                v-model="poor_value"
              />
              %
            </div>
          </div>
        </div>
      </div>

      <!---->
      <div
        v-show="elementVisible"
        class="bg-auto text-center rounded-xl font-normal mb-2 color-black bg-red-400 text-lg lg:text-xl p-2 w-full"
      >
        <p class="font-bold">
          The form could not be updated, please ensure that all required fields
          are filled out.
        </p>
      </div>
      <div class="flex justify-center mt-12 pb-5">
        <button
          class="w-4/6 lg:w-2/6 btn-primary font-regular text-lg lg:text-xl rounded-full h-12"
          @click="editForm()"
        >
          <i class="fas fa-plus-circle pr-3"></i>
          Edit form
        </button>
      </div>
    </div>
  </div>
</template>
<style scoped>
.custom-link {
  color: rgb(12 74 110);
}
.custom-link:hover {
  color: rgb(14 165 233);
}
</style>
<script>
import FormService from "../services/af-quote/FormService";
export default {
  data() {
    return {
      value: 50,
      form_id: null,
      domain: "",
      code: "",
      email: "",
      claim: "",
      congratulations_text: "",
      telephone: "",
      terms_link: "",
      primary_color: "",
      secondary_color: "",
      text_btn_color: "",
      background_input: "",

      background_color: "",
      selected_font: "",
      form_sended: false,
      correct_register: false,
      vue_api_url: "",
      excellent_value: 8,
      good_value: 10,
      fair_value: 14,
      poor_value: 20,

      domain_invalid: false,
      code_invalid: false,
      email_invalid: false,
      telephone_invalid: false,
      terms_link_invalid: false,
      domain_error: "",
      code_error: "",
      email_error: "",
      telephone_error: "",
      terms_link_error: "",

      elementVisible: false,
      show_checkbox: false,
      information_text: null,
    };
  },
  async created() {
    this.form_id = this.$route.params.id;
    this.vue_api_url = process.env.VUE_APP_API_URL;
    this.getForm(this.form_id);
  },
  computed: {
    total: function () {
      return this.value * 10;
    },
  },
  methods: {
    copyText() {
      var copyText = document.getElementById("calculator");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);

      // Alert the copied text
    },
    copyTextFinance() {
      var copyText = document.getElementById("finance");

      // Select the text field
      copyText.select();
      copyText.setSelectionRange(0, 99999); // For mobile devices

      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText.value);
    },
    async getForm(id) {
      let form_service = new FormService();
      let data = await form_service.getForm(id);
      const rating_percentage = JSON.parse(data.form.rating_percentage);

      if (rating_percentage == null) {
      } else {
        this.excellent_value = rating_percentage.excellent;
        this.good_value = rating_percentage.good;
        this.poor_value = rating_percentage.poor;
        this.fair_value = rating_percentage.fair;
      }

      this.domain = data.form.name;
      this.code = data.form.code;
      this.email = data.form.email;
      this.claim = data.form.claim;
      this.congratulations_text = data.form.congratulations_text;
      this.telephone = data.form.phone_number;
      this.terms_link = data.form.terms_link;
      this.primary_color = data.form.color_primary;
      this.secondary_color = data.form.color_secondary;
      this.text_btn_color = data.form.text_btn_color;
      this.background_input = data.form.background_input;
      this.background_color = data.form.background_color;
      this.selected_font = data.form.letter_type;
      this.show_checkbox = data.form.show_custom;
      this.information_text = data.form.custom_text;
      this.value = data.form.border_radius.replace("px", "");

      if (this.claim != null) {
        this.claim = this.claim.split("<br>").join("\n");
      }

      if (this.congratulations_text != null) {
        this.congratulations_text = this.congratulations_text
          .split("<br>")
          .join("\n");
      }

      this.terms_link = data.form.terms_link;
    },

    async editForm() {
      this.domain_invalid = false;
      this.code_invalid = false;
      this.email_invalid = false;
      this.telephone_invalid = false;
      this.terms_link_invalid = false;
      this.code_error = "";
      this.domain_error = "";
      this.email_error = "";
      this.telephone_error = "";
      this.terms_link_error = "";

      this.form_sended = true;

      if (this.code.length > 10) {
        this.code_invalid = true;
        this.code_error =
          "The length of the code cannot be longer than 10 characters";
      }

      if (this.domain.length == 0) {
        this.domain_invalid = true;
        this.domain_error = "Domain cannot be empty";
      }
      if (this.code.length == 0) {
        this.code_invalid = true;
        this.code_error = "Code cannot be empty";
      }
      if (this.email.length == 0) {
        this.email_invalid = true;
        this.email_error = "Email cannot be empty";
      }
      if (!this.validateEmail(this.email)) {
        this.email_invalid = true;
        this.email_error = "Invalid email format";
      }
      if (!this.validateTelephone(this.telephone)) {
        this.telephone_invalid = true;
        this.telephone_error = "Invalid telephone format";
      }
      if (this.telephone.length == 0) {
        this.telephone_invalid = true;
        this.telephone_error = "The telephone cannot be empty";
      }

      if (this.claim != null) {
        this.claim = this.claim.split("\n").join("<br>");
      }
      if (this.congratulations_text != null) {
        this.congratulations_text = this.congratulations_text
          .split("\n")
          .join("<br>");
      }
      if(this.show_checkbox == false){
        
        this.information_text = null;
      }
      let form = {
        name: this.domain,
        letter_type: document.getElementById("fonts").value,
        code: this.code,
        email: this.email,
        claim: this.claim,
        congratulations_text: this.congratulations_text,
        telephone: this.telephone,
        text_btn_color: document.getElementById("text_btn_color").value,
        border_radius: this.value + "px",
        color_primary: document.getElementById("primary_color").value,
        color_secondary: document.getElementById("secondary_color").value,
        background_input: document.getElementById("background_input").value,
        background_color: document.getElementById("background_color").value,
        text_color: document.getElementById("text_color").value,
        excellent_rate: this.excellent_value,
        good_rate: this.good_value,
        fair_rate: this.fair_value,
        poor_rate: this.poor_value,
        terms_link: this.terms_link,
        show_custom: this.show_checkbox,
        custom_text: this.information_text,
      };

    
      if (this.information_text == null && this.show_checkbox == true) {
        this.elementVisible = true;
        setTimeout(() => (this.elementVisible = false), 3000);
      } else {
        if (
          !this.code_invalid &&
          !this.email_invalid &&
          !this.domain_invalid &&
          !this.telephone_invalid &&
          !this.terms_link_invalid
        ) {
          let form_service = new FormService();
          let data = await form_service.updateForm(this.form_id, form);
          if (this.claim != null) {
            this.claim = this.claim.split("<br>").join("\n");
          }
          if (this.congratulations_text != null) {
            this.congratulations_text = this.congratulations_text
              .split("<br>")
              .join("\n");
          }

          if (data.isError == false) {
            this.correct_register = true;
            this.$router.push({ path: "/forms/" + "updated" });
          }
        } else {
          this.information_text=null;
          this.elementVisible = true;
          setTimeout(() => (this.elementVisible = false), 3000);
        }
      }
    },
    validateEmail(email) {
      var regex =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regex.test(email);
    },
    validateTelephone(telephone) {
      var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return regex.test(telephone);
    },
  },
};
</script>