<template>
  <div class="bg-white w-full list">
    <title>Zenfiny | List Brands</title>
    <div
      class="w-full bg-secondary py-2 lg:flex justify-between items-center px-3 lg:px-10"
    >
      <h1 class="text-white text-lg lg:text-2xl font-bold">
        <i class="fas fa-tags mr-2"></i>
        LIST BRANDS
      </h1>
      <div class="grid grid-cols-2 gap-4 mt-3 lg:mt-0">
        <a
          href="/registerbrand"
          class="text-sm lg:text-md btn-secondary inline-block lg:px-2 py-2 font-medium leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
        >
          <i class="fas fa-plus-circle mx-3"></i> ADD BRAND
        </a>
        <button
          class="text-sm lg:text-md font-medium btn-search inline-block lg:px-2 py-2 font-regular leading-tight uppercase rounded shadow-md hover:shadow-lg focus:bg-white focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full"
          @click="toggleCollapse"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="fas fa-search color-text mx-3"></i> SEARCH FILTER
        </button>
      </div>
    </div>
    <div style="min-height: 85.5vh" class="w-11/12 mx-auto">
      <!-- contenido del Collapse -->
    <transition name="slide-fade">
      <div v-if="isCollapsed" id="collapseExample" class="my-4" >
        <div class="block p-6 rounded-lg shadow-lg bg-color">
          <div class="grid grid-cols-2 gap-4">
            <div class="form-group mb-6">
              <label
                for="nameBrand"
                class="form-label inline-block mb-2 color-grey font-regular px-3 text-md "
                >Name</label
              >
              <input
                v-model="search_provisional_name"
                type="text"
                class="form-control block w-full px-3 py-2 text-sm font-normal t ext-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                id="nameBrand"
                placeholder=""
              />
            </div>
            <div class="form-group mb-6">
              <label
                for="urlWeb"
                class="form-label inline-block mb-2 color-grey font-regular px-3 text-md "
                >URL</label
              >
              <input
                v-model="search_provisional_url"
                type="text"
                class="form-control block w-full px-3 py-2 text-sm font-normal text-gray-700 bg-white border border-solid border-white rounded-full transition ease-in-out m-0 focus:text-gray-700 focus:border-gray-400 focus:outline-none"
                id="urlWeb"
                placeholder=""
              />
            </div>
          </div>
          <div class="flex justify-end">
            <button
              v-on:click.stop.prevent="
                setSearch();
                getBrands();
              "
              class="btn-primary inline-block px-2 py-2 font-regular text-sm leading-tight uppercase rounded shadow-md hover:shadow-lg focus:outline-none focus:ring-0 active:bg-white active:shadow-lg transition duration-150 ease-in-out rounded-full lg:w-32"
              type="button"
            >
              <i class="fas fa-search color-black mx-1"></i> SEARCH
            </button>
          </div>
        </div>
      </div>
    </transition>
      <div class="flex flex-col mt-5">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 inline-block min-w-full sm:px-6 lg:px-5">
            <div class="overflow-hidden">
              <table class="min-w-full">
                <thead class="bg-primary border-b-2">
                  <tr>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-4 py-2 text-left"
                    >
                      Brand
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-4 py-2 text-left"
                    >
                      Telephone
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-4 py-2 text-left"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      class="text-sm font-regular text-white px-2 py-2 text-left w-24"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="brand in brands"
                    v-bind:key="brand.id"
                    class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                  >
                    <td
                      class="text-sm font-semibold px-4 py-2 whitespace-nowrap"
                    >
                      {{ brand.name }}
                    </td>
                    <td class="text-sm px-2 py-2 whitespace-nowrap">
                      {{ brand.telephone }}
                    </td>
                    <td class="text-sm px-2 py-2 whitespace-nowrap">
                      {{ brand.email }}
                    </td>
                    <td
                      class="text-md text-left font-medium px-4 py-2 whitespace-nowrap flex justify-between"
                    >
                      <button
                        @click="editBrand(brand.id)"
                        type="button"
                        title="Edit"
                        class="inline-block py-2 font-medium text-md leading-tight color-black transition duration-150 ease-in-out pr-3 hover:opacity-70"
                      >
                        <i class="fas fa-edit color-grey"></i>
                      </button>
                      <button
                        @click="brand_delete = brand.id"
                        type="button"
                        title="Delete"
                        class="nline-block py-2 font-medium text-md leading-tight color-delete transition duration-150 ease-in-out hover:opacity-70"
                        data-bs-toggle="modal"
                        data-bs-target="#modalDelete"
                      >
                        <i class="far fa-trash-alt"></i>
                      </button>
                    </td>
                   
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Assigned brands -->
      <div
        class="modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto"
        id="modalDelete"
        tabindex="-1"
        aria-labelledby="modalDelete"
        aria-modal="true"
        role="dialog"
      >
        <div
          class="modal-xl modal-dialog modal-dialog-centered relative w-auto pointer-events-none"
        >
          <div
            class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
          >
            <div
              class="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current"
            >
              <div
                class="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md bg-primary"
              >
                <h5
                  class="text-2xl font-regular leading-normal text-white"
                  id="exampleModalScrollableLabel"
                >
                  Delete brand
                </h5>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  class="box-content w-8 h-8 p-1 text-white border-none rounded-none opacity-100 focus:outline-none hover:text-gray-200 hover:opacity-75 hover:no-underline"
                  aria-label="Cancel"
                >
                  <i class="fas fa-times"></i>
                </button>
              </div>
              <div class="modal-body relative py-6 px-2">
                <p>
                  Are you sure you want to remove this brand and all his quotes
                  related?
                </p>
              </div>
              <div
                class="modal-footer flex flex-shrink-0 flex-wrap items-center justify-end p-4 rounded-b-md"
              >
                <button
                  type="button"
                  v-on:click.stop.prevent="deleteBrand(brand_delete)"
                  data-bs-dismiss="modal"
                  class="inline-block px-6 py-2 btn-delete font-regular text-md leading-tight uppercase rounded-full shadow-md focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1"
                >
                  <i class="far fa-trash-alt"></i>
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div aria-label="Page navigation" class="bg-transparent pb-4">
      <div class="flex flex-col mt-5">
        <ul class="inline-flex flex justify-center">
          <li>
            <button
              @click="getBrands(current_page - 1)"
              :disabled="current_page == 1"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black rounded-l-lg focus:shadow-outline hover:bg-indigo-100"
            >
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
          <li v-if="current_page > 1">
            <button
              @click="getBrands(1)"
              :disabled="current_page == 1"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              1
            </button>
          </li>
          <li v-if="current_page > 2">
            <button
              disabled
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              ...
            </button>
          </li>

          <li>
            <button
              disabled
              class="h-10 px-5 text-white transition-colors duration-150 bg-primary border border-r-0 border-black focus:shadow-outline"
            >
              {{ current_page }}
            </button>
          </li>

          <li v-if="current_page < last_page - 1">
            <button
              disabled
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              ...
            </button>
          </li>
          <li v-if="current_page < last_page">
            <button
              @click="getBrands(last_page)"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-r-0 border-black focus:shadow-outline"
            >
              {{ last_page }}
            </button>
          </li>

          <li>
            <button
              @click="getBrands(current_page + 1)"
              :disabled="current_page == last_page"
              class="h-10 px-5 text-black transition-colors duration-150 bg-white border border-black rounded-r-lg focus:shadow-outline hover:bg-indigo-100"
            >
              <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
                <path
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import BrandService from "../../services/af-quote/BrandService";

export default {
  data() {
    return {
      isCollapsed: false,

      brands: null,

      brand_delete: null,

      search_name: null,
      search_url: null,

      search_provisional_name: null,
      search_provisional_url: null,

      //pagination
      current_page: null,
      last_page: null,
      show_deleted: false,

      modalDelete: false,
    };
  },

  async created() {
    await this.getBrands();
  },

  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;  // Alterna el estado del colapso
    },
    async getBrands(num_page = null) {
      let query = this.buildSearchQuery();
      if (num_page != null)
        query += query != "" ? "&page=" + num_page : "page=" + num_page;
      try {
        const brand_service = new BrandService();
        const brand_data = await brand_service.getBrands(query);
        this.brands = brand_data.data;
        this.current_page = brand_data.current_page;
        this.last_page = brand_data.last_page;
      } catch (error) {
        console.error(error);
      }
    },

    editBrand(id) {
      this.$router.replace({ name: "registerBrand", params: { id: id } });
    },

    async deleteBrand(brand_id) {
      try {
        const brand_service = new BrandService();
        await brand_service.deleteBrand(brand_id);

        if (this.brands.length == 1 && this.current_page > 1)
          this.getBrands(--this.current_page);
        else this.getBrands(this.current_page);
      } catch (error) {
        console.error(error);
      }
    },

    buildSearchQuery() {
      let query = "";
      if (this.search_name != null && this.search_name != "")
        query += "name=" + this.search_name;
      if (this.search_url != null && this.search_url != "")
        query +=
          query == null ? "url=" + this.search_url : "&url=" + this.search_url;
      return query;
    },

    setSearch() {
      this.search_url = this.search_provisional_url;
      this.search_name = this.search_provisional_name;
    },
  },
};
</script>

<style>

.slide-fade-enter-active {
  transition: all 0.6s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active en <2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}

</style>