<template>
  <div ref="pdf_download" class="bg-white w-full p-12" style="position: relative">
    <div style="height: 1050px; position: relative">
      <div class="flex justify-between items-center">
        <div style="max-width: 24vh;">
          <img
            class="img-fluid"
            :src="image_brand"
            alt=""
          />
        </div>
        <div class="w-auto text-sm">
          <a class="font-semibold" :href="`tel:${quote.brand.telephone}`"
            >Tel: {{ quote.brand.telephone }}</a
          ><br />
          <a class="font-semibold" :href="`mailto:${quote.brand.email}`"
            >Email: {{ quote.brand.email }}</a
          ><br />
          <a class="font-semibold" :href="quote.brand.url">{{
            quote.brand.url
          }}</a>
        </div>
      </div>
      <div class="mt-20 mb-6">
        <div class="flex justify-between items-center">
          <h1 class="text-3xl font-extrabold">Your Quote</h1>
          <p class="text-md w-auto font-semibold">{{ date(quote.updated_at) }}</p>
        </div>

        <h3
          style="letter-spacing: 0.8px; border-radius:2rem"
          class="w-3/12 text-center font-extrabold border-2 border-black text-2xl py-2 px-1 mb-6 mt-8"
        >
          {{ quote.code }}
        </h3>

        <div class="mt-6">
          <p class="mb-8 font-bold text-md text-black">Dear {{ quote.name }},</p>
          <p class="mb-4 text-md">
            We are delighted to provide you with a quote for finance as per below.
          </p>
          <div class="my-6 text-gray-700">
            <div class="flex">
              <p class="font-bold pr-2 text-md">Type</p>
              <div v-if="quote.type == 'hire_purchase'">
                <p class="text-md font-bold">Hire purchase</p>
              </div>
              <div v-else>
                <p class="text-md font-bold">Lease</p>
              </div>
            </div>
            <!--Cost-->
            <div class="flex">
              <p class="font-bold pr-2 text-md">Cost</p>
              <div v-if="quote.type == 'hire_purchase'">
                <p v-if="quote.vat_deferal" class="text-md font-bold">
                  £ {{ formatNumber(quote.cost) }} + VAT (£ {{ formatNumber(quote.vat)}})
                </p>
                <p v-else class="text-md font-bold">
                  £ {{ formatNumber(quote.cost) }} + VAT (£ {{ formatNumber(quote.vat)}})
                </p>
              </div>
              <div v-else>
                <p class="text-md font-bold">
                  £ {{ formatNumber(quote.cost)}} + VAT (£ {{ formatNumber(quote.vat)}})
                </p>
              </div>
            </div>

            <div v-if="quote.not_vat_items" class="flex">
                    <p class="font-bold pr-2 text-md">Not vat items:</p>
                    <p class="text-md font-bold"> £ {{ formatNumber(quote.not_vat_items) }}</p>
                  </div>
                  <div v-if="quote.not_vat_items" class="flex">
                    <p class="font-bold pr-2 text-md">Total Net:</p>
                    <p class="text-md font-bold"> £ {{ totalNetCost }}  + VAT (£ {{ formatNumber(quote.vat)}})</p>
                  </div>

            <!--Deposit-->
            <div class="flex">
            <div>
              <p v-if="quote.deposit" class="font-bold pr-2 text-md">Deposit</p>
              <p v-else class="font-bold pr-2 text-md">No deposit</p>
            </div>
            <!--Lease Quote-->
              <div v-if="quote.deposit && quote.type == 'lease'">
                <p class="text-md font-bold">{{ quote.deposit ? "£ " + formatNumber(quote.deposit) + 
                " + VAT (£ "+ formatNumber(0.2*quote.deposit) +") " : '' }}
                </p>
              </div>
              <div v-else>
              <!--Hire Purchase Quote-->
                <!--VAT deferral-->
                
                <div v-if="quote.vat_deferal" class="text-md font-bold">
                  <p>{{ quote.deposit != null ? "£ " + formatNumber(quote.deposit) : " " }}</p>
                </div>
                <!--No VAT deferral-->
                <div v-if="!quote.vat_deferal" class="text-md font-bold">
                  <!--Net deposit-->
                  
                  <p class="text-md font-bold">
                    {{`£ ${formatNumber(parseFloat(quote.deposit) + parseFloat(quote.vat))} inc VAT`}}
                  </p>
                </div>
              </div>
            </div>

            <!--Quote terms-->
            <div class="flex" v-if="quote.type === 'hire_purchase'">
              <p class="font-bold pr-2 text-md">Approx</p>
              <p class="text-md font-bold">
                {{ quote.term + " x " + "£ " + formatNumber(quote.payment)}}
              </p>
              <p v-if="quote.balloon != null || quote.balloon > 0" class="font-bold pr-2 text-md">
                &nbsp;+ Balloon £ {{formatNumber(quote.balloon)}} included for month {{ quote.balloon_fall }}
              </p>
            </div>
            <div v-else-if="quote.vat_deferal" class="flex">
              <p class="font-bold pr-2 text-md">Approx</p>
              <p class="text-md font-bold">
                {{ quote.term + " x " + "£ " + formatNumber(quote.payment)}}
              </p>
              <p v-if="quote.balloon != null || quote.balloon > 0" class="font-bold pr-2 text-md">
                &nbsp;+ Balloon £ {{formatNumber(quote.balloon)}} included for month {{ quote.balloon_fall }}
              </p>
            </div>
            <div v-else class="flex">
              <p class="font-bold pr-2 text-md">Approx</p>
              <p class="text-md font-bold">
                {{ quote.term + " x " + "£ " + formatNumber(quote.payment)  + " + VAT (£ "+ formatNumber(0.2*quote.payment) +") "}}
              </p>
            </div>
            <div v-if="quote.vat_deferal" class="flex">
              <p class="font-bold pr-2 text-md">
                VAT Deferral of £ {{formatNumber(quote.vat)}} included for month {{ quote.balloon_fall }}
              </p>
            </div>
          </div>
          <div class="my-4 text-2xl">
            <p class="italic">
              <span class="font-medium">* </span>Please note that the above is
              subject to full credit and terms may change.
            </p>
            <p class="mt-5" style="line-height: 20px">
              If you would like to proceed or have any questions please contact us
              on
              <a href="tel:${quote.brand.email}">{{ quote.brand.telephone }}</a>
              Where one of the team will be more than happy to help.
            </p>
          </div>
        </div>
        <div class="mt-20 w-5/12">
          <p class="text-lg font-semibold">Yours sincerely,</p>
          <img class="h-20" src="/img/signature2.png" alt="" />
          <p class="my-4 text-md font-bold">{{ quote.brand.name }}</p>
        </div>
      </div>
      <div class="footer text-center mt-10 absolute bottom-6">
        <p style="font-size: 0.6em" class="font-light">
          {{ quote.brand.name }} is a credit broker and not a lender. Finance is
          available subject to status. Terms & Conditions apply. Indemnities may
          be required. We work with a number of carefully selected credit
          providers who may be able to offer you finance. Commission will be
          received. We are only able to offer finance products from these
          providers. Our registered office is
          {{ quote.brand.address_line_1 }} RH121EH, FCA 798352.
        </p>
      </div>
    </div>
    <!-- nuevos textos legales -->
    <div style="padding: 10px; padding-top: 50px;" >
      <h2 style="text-align: center; font-width: 500; font-size: 1.3rem; padding-bottom: 20px;">TERMS OF BUSINESS</h2>
       <table id="legal-text">
            <tr>
                <td class="left-column"><strong>The Broker</strong></td>
                <td class="right-column">{{ quote.brand.name }} </td>
            </tr>
            <tr>
                <td class="section-title"><strong>Defined terms;</strong></td>
                <td></td>
            </tr>
            <tr>
                <td class="left-column"><strong>Completion;</strong></td>
                <td class="right-column">The first drawdown of the Loan Amount set out in the Finance Offer by the Lender to the Client</td>
            </tr>
            <tr>
                <td class="left-column"><strong>Loan Amount;</strong></td>
                <td class="right-column">the sum of any amounts payable by the Lender to the Client following the issue of a Finance Offer</td>
            </tr>
            <tr>
                <td class="left-column"><strong>Lender; Lending</strong></td>
                <td class="right-column">any lender to whom the Lending Proposal is presented</td>
            </tr>
            <tr>
                <td class="left-column"><strong>Proposal;</strong></td>
                <td class="right-column">the proposal prepared by the Broker setting out the requirements recorded in the Confirmation of Instructions provided in accordance with paragraph 1 of the attached Terms and Conditions as varied from time to time</td>
            </tr>
            <tr>
                <td class="left-column"><strong>Finance Offer ;</strong></td>
                <td class="right-column">a written offer setting out proposed terms of finance issued by any Lender whether such offer is conditional or unconditional or any replacement thereof and which Is accepted by the client within 6 months of this agreement. </td>
            </tr>
            <tr>
                <td class="left-column"><strong>You;</strong></td>
                <td class="right-column">{{ quote.name }}</td>
            </tr>
        </table>
        <!-- tabla 2 -->
        <table class="legal-text-2" style="margin-top: 20px;">
            <tr>
                <td class="left-column"><strong>1. </strong></td>
                <td><strong>Appointment of the Broker</strong></td>
            </tr>
            <tr>
                <td></td>
                <td>This document sets out how we will deal with you in the provision of services for Credit Broking. CAPC Commercial Ltd is authorised and regulated by the Financial Conduct Authority (FCA).</td>
            </tr>
            <tr>
                <td class="left-column"><strong>2. </strong></td>
                <td><strong>Authorisation Statement</strong></td>
            </tr>
            <tr>
                <td></td>
                <td>CAPC Commercial Ltd is Authorised and Regulated by the Financial Conduct Authority. The Financial Conduct Authority (FCA) regulates financial services in the UK and you can check our authorisation and permitted activities on the Financial Services Register by visiting the FCA’s website www.fca.org.uk/firms/systems-reporting/register or by contacting the FCA on 0800 111 6768.</td>
            </tr>
            <tr>
                <td class="left-column"><strong>3. </strong></td>
                <td><strong>Client Acknowledgement</strong></td>
            </tr>
            <tr>
                <td style="padding-left: 2%">3.1</td>
                <td>You acknowledge that:</td>
            </tr>
            <tr>
              <td class="subtitle">3.1.1</td>
              <td>You have been urged to seek such independent advice as you consider necessary before signing this Agreement;</td>
            </tr>
            <tr>
                <td class="subtitle">3.1.2</td>
                <td>The Broker sources Offers of Funding from a number of carefully selected lender provides who are able to offer you finance</td>
            </tr>
                <tr>
              <td class="subtitle">3.1.3</td>
              <td>The Broker will receive commission from the Lender</td>
            </tr>
            <tr>
                <td class="subtitle">3.1.4</td>
                <td>You have read the brokers standard Terms and Conditions and agree that they form part of this agreement</td>
            </tr>
            <tr>
              <td  colspan="2">You are aware that the broker wil recieve commision from the lenders for the arangement of this ageeement and you have no objections for the broker receiveing that;</td>
            </tr>
            <tr>
              <td  colspan="2">You are aware that the Broker will receive commission Lender for the arrangement of this agreement and you have no objections to the Broker receiving this amount. – see attached Terms and Conditions para 3. </td>
            </tr>
            <tr>
                <td class="left-column"><strong>4. </strong></td>
                <td><strong>You authorise/do not authorise the Broker to contact you by any means with marketing material.</strong></td>
            </tr>
        </table>
        <!-- tabla 3 -->
        <table  class="legal-text-2" style="margin-top: 250px;">
          <tr>
            <td  colspan="2" style="font-size: 20px;"><strong>TERMS AND CONDITIONS</strong></td>
          </tr>
          <tr>
              <td><strong>1.</strong></td>
              <td><strong>Confirmation of Instructions</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">1.1</td>
              <td  style="padding-top: 20px;">Before signing the Agreement the Broker will complete a Confirmation of Instructions (the Instructions) which shall be read and take effect as if they form part of the Agreement.</td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">1.2</td>
              <td style="padding-top: 20px;">Any change to or variation of the Instructions will not affect the liability of the Client to pay any fee pursuant to the Agreement. </td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>2.</strong></td>
              <td style="padding-top: 20px;"><strong>The Client’s Duty to the Broker</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">2.1</td>
              <td style="padding-top: 20px;">You agree to be act with utmost good faith in the provision of information to the Broker. The duty is continuous and applies to all the information you provide, whether we have asked for it or whether you have provided it voluntarily. You agree not to withhold information from us. </td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">2.2</td>
              <td style="padding-top: 20px;">You agree to take all reasonable steps and use all reasonable endeavours to comply with and satisfy any condition imposed by the Lender who has made a Finance Offer that accords with the requirements set out in the Confirmation of Instructions letter. </td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">2.3</td>
              <td style="padding-top: 20px;">You agree to notify the Broker if at any time you intend to appoint an additional or alternate broker or intermediary to obtain an offer of finance for you whereupon the Broker will be entitled to terminate this Agreement forthwith.</td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">2.4</td>
              <td>If you fail to notify the Broker that you intend to appoint an alternate or additional broker or intermediary prior to doing so and if an offer of finance is obtained from any Lender You will pay the Arrangement Fee calculated by reference to the finance offer made but otherwise in accordance clause 3.2 of the Agreement to the Broker. </td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>3.</strong></td>
              <td style="padding-top: 20px;"><strong>Introductory Commission</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">3.1</td>
              <td>The Lender will pay the Broker introductory commission in respect of the funding set out in the Finance Offer. If commission is to be paid, and the amount of commission is known to the Broker prior to acceptance of the Finance Offer, the Client will be informed that commission will be paid. Commission paid to the Broker may vary in amount depending on the Lender or product. </td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">3.2</td>
              <td>The Client having acknowledged the matters set out under the Client Acknowledgment consents to the Broker receiving and retaining any commission paid. </td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>4.</strong></td>
              <td style="padding-top: 20px;"><strong>Termination</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">4.1</td>
              <td>The Broker may terminate the Agreement by giving 14 days written notice to the client. </td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>5.</strong></td>
              <td style="padding-top: 20px;"><strong>Complaints</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;"></td>
              <td>The Broker will investigate and deal with any complaints raised by the Client concerning the services provided under this Agreement promptly and reasonably but if the Broker is unable to resolve any complaint to the Client’s satisfaction the Code stipulates the procedures available to the Client including arbitration.  </td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>6.</strong></td>
              <td style="padding-top: 20px;"><strong>Data Protection</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">6.1</td>
              <td>The Client agrees that any information he provides which may be held and processed by the Broker may be shared with the Lenders, third parties to protect the Broker against fraud, to satisfy the Code and in accordance with any statutory or legal obligation the Broker might have. </td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">6.2</td>
              <td>The Broker may use and disclose details of this application and any resulting Offer of Funding to the Client to any Lender, any intended guarantor any investor and their legal advisors and may further use and disclose information to the Financial Conduct Authority, NACFB, any processing agents with whom the Broker may hold an agreement to process data.</td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;">6.3</td>
              <td>In accordance with the Data Protection Act 2018 the Client is advised that information provided may be held by the Broker in its computer and papers records and retained for a period of six years after the date of this Agreement.</td>
          </tr>
          <tr>
              <td style="padding-top: 100px;"><strong>7.</strong></td>
              <td style="padding-top: 100px;"><strong>Amendments</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;"></td>
              <td>Any amendment whether proposed by the Broker or the Client shall be notified in writing to the other party. Any amendment proposed by the Broker shall take effect on the date specified unless in the meantime the Client notifies the Broker to the contrary or requests an extension of time. Any amendment proposed by the Client shall take effect when accepted by the Broker in writing.</td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>8.</strong></td>
              <td style="padding-top: 20px;"><strong>Assignments and third party rights</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;"></td>
              <td>This Agreement is personal to the Client and a person who is not a party to this Agreement may not enforce any of its terms under the Contracts (Rights of Third Parties) Act 1999.</td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>9.</strong></td>
              <td style="padding-top: 20px;"><strong>Professional Indemnity </strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;"></td>
              <td>The Broker will maintain professional indemnity insurance cover in respect of its business with and on standard terms offered by reputable insurers.</td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>10.</strong></td>
              <td style="padding-top: 20px;"><strong> Exclusions </strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;"></td>
              <td>The Client accepts that the Broker shall have no liability for any economic loss (whether direct, indirect or consequential) insofar as it relates in any way to loss of business, loss of Client data, interruption of business or loss of profits or goodwill as a result of the manner of performance by the Broker of any obligations arising under this Agreement. This exclusion of liability will not, however, otherwise affect any statutory rights of the Client</td>
          </tr>
          <tr>
              <td style="padding-top: 20px;"><strong>11.</strong></td>
              <td style="padding-top: 20px;"><strong>Choice of Law</strong></td>
          </tr>
          <tr>
              <td class="subtitle" style="padding-top: 20px;"></td>
              <td>The Agreement shall be construed in accordance with English Law and the parties irrevocably agree to submit to the exclusive jurisdiction of the English Courts.</td>
          </tr>
        </table>
        <div style="padding-top: 40px;" id="text">
          <h3 style="font-weight: 600; font-size: 20px;">DATA PROTECTION ACT </h3>
          <p style="padding-top: 20px;">In order to process the application for funding the following information may need to be supplied, including accounts and personal financial details, by you, the Client, and by other sources such as your Bank and credit reference agencies.</p>
          <p>We may also, when authorised by you in writing, apply for supplementary information from your professional or other advisers.</p>
          <p style="padding-top: 20px;">We will record this information (whether in manually operated or automated systems) and retain it only as long as we are required by law to do so. We will keep it as securely as possible. </p>
          <p style="padding-top: 20px;">In order to do so, we will disclose such of this information as is necessary to negotiate terms with a funding source, with employees of this business, any guarantor required by the funder, any person or organisation necessary to comply with any legal or regulatory requirements to which we may be subject, and anyone else whom you may specifically authorise in writing. </p>
          <p style="padding-top: 20px;">We may also use the information to monitor and analyse our business and, unless you instruct us in writing not to do so, contact you with details of products or services which may be of interest to you.</p>
          <p style="padding-top: 20px;">In the event that we search with credit reference agencies, we will need to disclose information about you. The credit reference agencies will record details of our searches. If you would like details of the credit reference agencies please write to us at the address stated.</p>
          <p >You have the right to apply to us for details of the information we hold about you. An administrative charge may be made by us for doing so. </p>
          <p style="padding-top: 200px;"><strong>The Broker has been instructed by the Client to obtain an offer of funding from a reputable lending source. The following terms are those advised by the Broker as likely to apply to any Offer of funding. They are subject to the Client’s acceptance of the Broker’s formal Terms of Business Agreement. When the Agreement has been signed by both parties, the Confirmation of Instructions will form a contractual part of the Agreement.</strong></p>
          <p style="padding-top: 20px;">The Broker cannot guarantee that these terms will be achieved. Any lender selected by the Broker will undertake a thorough examination of the Client’s ability to service the loan. This will include approval of accounting and financial information and a valuation for bank purposes of any property offered as security, Approval of this information is at the sole discretion of the lender (and not the Broker). A number of factors, beyond the control of the Broker, may emerge in the course of this process and cause the lender to weight, or vary, the terms indicated in this memorandum which will be revised accordingly. </p>
        </div>
    </div>
  </div>

</template>

<script>
import moment from "moment";
import BrandService from "../../services/af-quote/BrandService";
import { mapGetters } from "vuex";

export default {
  name: "pdf",
  props: ["with_monthly", "monthly_history", "quote", "image_brand"],
  methods: {
    formatNumber(x) {
      x = parseFloat(x);

      let nf = new Intl.NumberFormat("en-UK", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });

      nf.resolvedOptions().minimumFractionDigits = 2;
      return nf.format(x);
    },
    date(value) {
      return moment(value).format("MMMM DD, yyyy");
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/authUser",
    }),
    totalNetCost:function(){
      var not_vat_items = parseFloat(this.quote.not_vat_items);
      var cost = parseFloat(this.quote.cost);
      //console.log(not_vat_items+cost);
      return this.formatNumber(not_vat_items+cost);
    },
  }
};
</script>
